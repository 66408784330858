export const experienceData = [
    {
        id: 1,
        company: 'Persistent',
        jobtitle: 'Software Engineer',
        startYear: 'October 2020',
        endYear: 'July 2022'
    },
    {
        id: 2,
        company: 'Ellucian',
        jobtitle: 'Software Engineer Intern',
        startYear: 'January 2020',
        endYear: 'April 2020'
    },
]